import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Fade } from "react-reveal"


const StyledTitle = styled.h1`
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    font-size: 46px;
    letter-spacing: 3px;
    color: white;
    text-align: left;
    padding-left: 24px;
    position: absolute;
    bottom: 0;
    line-height: 46px;
    @media(min-width: 768px) {
        font-size: 62px; 
        letter-spacing: 4px;
        line-height: 64px;
        padding-left: 0;
        max-width: 370px;
    }
    @media(max-width: 400px) {
        font-size: 30px;
        letter-spacing: 1px;
        line-height: 30px;
        padding-left: 15px;
    }
`
const WhiteLine = styled.div`
    margin-bottom: 0;
`
const OutlinedLine = styled.div`
    color: transparent;
	  -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(255,255,255,0.6);
    margin-top: 0;
    margin-bottom: 0;
`

const Title = () => {
  return (
    <StyledTitle>
      <Fade duration={750} delay={500}>
        <OutlinedLine>Las</OutlinedLine>
      </Fade>
      <Fade duration={750} delay={750}>
        <WhiteLine>Intro-ducing</WhiteLine>
      </Fade>
    </StyledTitle>
  )
}

export default Title