import React, { Component } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import Img from "gatsby-image"
import RightArrow from "../../resources/images/happenings/white-arrow-right.svg"
import LeftArrow from "../../resources/images/happenings/white-arrow-left.svg"
import Play from "../../resources/images/home/play-video.png"

const SliderContainer = styled.div`
  width: 100%;
`

const Slide = styled.div`
  outline: none;
  border: 0;
  margin: auto;
`

const StyledSlider = styled(Slider)`
  .slick-track,
  .slick-list,
  .slick-slide {
    height: 100%;
    width: auto;
  }
  }
`

const CarouselController = styled.img`
  cursor: pointer;
  width: 40px;
  height: 6px;
  border: none;
`

const Previous = styled.span`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  margin-top: 45px;
  right: 57%;
  @media (min-width: 992px) {
    bottom: 29%;
    right: 64px;
    transform: rotate(270deg);
    margin-top: 0;
  }
`

const Next = styled.span`
  cursor: pointer;
  z-index: 1;
  position: absolute;
  margin-top: 45px;
  right: 35%;
  @media (min-width: 992px) {
    bottom: 22%;
    right: 55px;
    transform: rotate(90deg);
  }
`

const SlideImg = styled(Img)`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  z-index: 1;
  display: ${props => props.visible ? "block" : "none"};
  img {
    width: 100%;
    height: 100%;
  }

`

const SlideBox = styled.div`
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: ${props => props.video ? "block" : "none"};

  }
`

const DesktopOnly = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`

const MobileOnly = styled.div`
  display: block;
  @media (min-width: 992px) {
    display: none;
  }
`

const PlayButton = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: ${props => props.visible ? "block" : "none"};
  img {
    height: 64px;
    width: 64px;
  }
  @media (min-width: 992px) {
    img {
      height: 80px;
      width: 80px;
    }
  }

`

const Pulse = styled.span`
  position: absolute;
  width: 64px;
  height: 64px;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  &:before {
    content: "";
    position: relative;
    display: block;
    width: 150%;
    height: 150%;
    box-sizing: border-box;
    margin-left: -25%;
    margin-top: -25%;
    border-radius: 100%;
    background-color: #9ddce2;
    animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
    80%,
    100% {
      opacity: 0;
    }
  }
  @media (min-width: 992px) {
    height: 80px;
    width: 80px;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
  }
`

class PostCarousel extends Component {
  state = {
    slideIndex: 0,
    videoPlaying: false,
    width: 0
  }

  changeSlide(diff) {
    const slideIndex = this.state.slideIndex + diff

    this.slider.slickGoTo(slideIndex)
  }

  playVideo = () => {
    this.setState({videoPlaying: !this.state.videoPlaying})
  }

  render() {
    const { slides } = this.props

    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      fade: false,
      swipe: true,
      swipeToSlide: true,
      draggable: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      initialSlide: 0,
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    }

    return (
      <SliderContainer>
        <StyledSlider ref={slider => (this.slider = slider)} {...settings}>
          {slides.map((slide, index) => {
            return (
              <Slide key={"image-" + index}>
                <SlideBox video={this.state.videoPlaying}>
                  <SlideImg fluid={slide.image.childImageSharp.fluid}  visible={!this.state.videoPlaying}/>
                  <PlayButton onClick={this.playVideo} visible={!this.state.videoPlaying}><img src={Play} alt="Play Video" /><Pulse/></PlayButton>
                  <iframe
                    src={`https://player.vimeo.com/video/503403569?autoplay=${this.state.videoPlaying ? "1" : "0"}&loop=1&controls=1`}
                    title="Introducing Video"
                    frameBorder="0"
                    allow="fullscreen; autoplay"
                  />
                </SlideBox>
              </Slide>
            )
          })}
        </StyledSlider>
        <DesktopOnly>
          <Previous onClick={() => this.changeSlide(-1)}>
            <CarouselController src={RightArrow} />
          </Previous>
          <Next onClick={() => this.changeSlide(1)}>
            <CarouselController src={RightArrow} />
          </Next>
        </DesktopOnly>
        <MobileOnly>
          <Previous onClick={() => this.changeSlide(-1)}>
            <CarouselController src={LeftArrow} />
          </Previous>
          <Next onClick={() => this.changeSlide(1)}>
            <CarouselController src={RightArrow} />
          </Next>
        </MobileOnly>
      </SliderContainer>
    )
  }
}

export default PostCarousel
