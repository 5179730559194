import React from "react"
import { Zoom } from "react-reveal"
import DecorationTitleImg1 from "../../resources/images/introducing/decoration-title-1.svg"
import DecorationTitleImg2 from "../../resources/images/introducing/decoration-title-2.svg"
import DecorationTitleImg3 from "../../resources/images/introducing/decoration-title-3.svg"
import DecorationTitleImg4 from "../../resources/images/introducing/decoration-title-4.svg"
import DecorationTitleImg5 from "../../resources/images/introducing/decoration-title-5.svg"
import DecorationTitleImg6 from "../../resources/images/introducing/decoration-title-6.svg"
import DecorationTitleImg7 from "../../resources/images/introducing/decoration-title-7.svg"
import DecorationTitleImg8 from "../../resources/images/introducing/decoration-title-8.svg"
import DecorationTitleImg9 from "../../resources/images/introducing/decoration-title-9.svg"
import DecorationTitleImg10 from "../../resources/images/introducing/decoration-title-10.svg"
import DecorationTitleImg11 from "../../resources/images/introducing/decoration-title-11.svg"
import DecorationTitleImg12 from "../../resources/images/introducing/decoration-title-12.svg"


import styled from "styled-components"

const DecorationBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 72px;
    padding-top: 80px;
    @media(min-width: 992px) {
        padding-bottom: 156px;
        padding-top: 200px;
    }
`;

const TitleLetter = styled.img`
    height: 21px;
    @media(min-width: 576px){
        height: 38px;
    }
    @media(min-width: 992px){
        height: 64px;
    }
    @media(min-width: 1161px){
        height: 78px;
    }
`;

const Break = styled.div`

`;

const DecorationTitle = () => {
  return (
    <DecorationBox>
      <Zoom cascade duration={3000}>
        <TitleLetter src={DecorationTitleImg1}/>
        <TitleLetter src={DecorationTitleImg2}/>
        <TitleLetter src={DecorationTitleImg3}/>
        <TitleLetter src={DecorationTitleImg4}/>
        <TitleLetter src={DecorationTitleImg5}/>
        <TitleLetter src={DecorationTitleImg6}/>
        <Break/>
        <TitleLetter src={DecorationTitleImg7}/>
        <TitleLetter src={DecorationTitleImg8}/>
        <TitleLetter src={DecorationTitleImg9}/>
        <TitleLetter src={DecorationTitleImg10}/>
        <TitleLetter src={DecorationTitleImg11}/>
        <TitleLetter src={DecorationTitleImg12}/>
      </Zoom>
    </DecorationBox>
  )
}

export default DecorationTitle
