import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import { Container } from "styled-bootstrap-grid"
import { Fade } from "react-reveal"
import EventEntry from "./EventEntry"
import BlueLineImg from "../../resources/images/blue-line.svg"
import BlockBackgroundImgMobile from "../../resources/images/introducing/intro-background-mobile.jpg"
import BlockBackgroundImg from "../../resources/images/introducing/intro-background.jpg"

const BlackBackground = styled.div`
  background: #000914;
  width: 100%;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
`

const EventsBackground = styled.div`
  position: relative;
  background: url(${BlockBackgroundImgMobile}) no-repeat;
  min-height: 740px;
  background-position: center bottom;
  background-size: cover;
  @media (min-width: 586px) {
    width: 100vw;
    min-height: 621px;
    background: url(${BlockBackgroundImg}) no-repeat;
    background-position: 100%;
    background-position-y: 40%;
    background-size: contain;
  }
`

const ContentContainer = styled(Container)`
  padding: 100px 0 0 0;
  @media (min-width: 992px) {
    padding-top: 151px;
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledTitle = styled.h1`
  text-transform: uppercase;
  ${fonts.swissBlackExtended};
  font-size: 34px;
  letter-spacing: 2.2px;
  color: white;
  text-align: center;
  line-height: 46px;
  padding-bottom: 50px;
  @media (min-width: 768px) {
    font-size: 62px;
    letter-spacing: 4px;
    line-height: 64px;
  }
  @media (min-width: 992px) {
    padding-bottom: 94px;
  }
  @media (max-width: 400px) {
    font-size: 24px;
    letter-spacing: 1px;
  }
`

const WhiteLine = styled.div`
  margin-bottom: 0;
`

const OutlinedLine = styled.div`
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.6);
  margin-top: 0;
  margin-bottom: 0;
`

const BlueLine = styled.img`
  width: 80px;
`

const NoEvent = styled.div`
    font-size: 15px;
    line-height: 21px;
    color: #9CA8B5;
    letter-spacing: 0;
    line-height: 21px;
    ${fonts.gilroyRegular};
    padding-top: 40px;
    padding-bottom: 50px;
    text-align: center;
    margin: auto;
    @media(min-width: 992px){
        padding-top: 50px;
        padding-bottom: 30px;
        max-width: 361px;
    }
`

const EntryContainer = styled.div`
  padding-bottom: 40px;
  @media(min-width: 768px){
    padding-bottom: 100px;
  }
`

const UpcomingEventsBlock = props => {
  const { articles } = props

  return (
    <BlackBackground>
      <EventsBackground>
        <ContentContainer>
          <StyledTitle>
            <Fade duration={750} delay={500}>
              <WhiteLine>Upcoming</WhiteLine>
            </Fade>
            <Fade duration={750} delay={750}>
              <OutlinedLine>Events</OutlinedLine>
            </Fade>
            <BlueLine src={BlueLineImg} />
          </StyledTitle>
          <EntryContainer>
            {articles.length > 0 ? (
              articles.map((article, index) => {
                return <EventEntry {...article} reverse={index % 2 === 0} />
              })
            ) : (
              <NoEvent>
                Coming Soon...
              </NoEvent>
            )}
          </EntryContainer>
        </ContentContainer>
      </EventsBackground>
    </BlackBackground>
  )
}

export default UpcomingEventsBlock
