import React from "react"
import styled from "styled-components"
import { Row, Col } from "styled-bootstrap-grid"
import Img from "gatsby-image"
import fonts from "../../styles/fonts"
import VerticalLineImg from "../../resources/images/happenings/vertical-line.svg"
import HorizontalLineImg from "../../resources/images/happenings/horizontal-line.svg"
import Button from "../core/Button"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`;

const NewsRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 33px;
    padding-right: 33px;
    
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;  
        padding-top: 40px;
        padding-bottom: 40px;
        :first-of-type {
          padding-top: 0;
        }
        :last-of-type {
          padding-bottom: 0;
        }
    }
`;

const StyledNewsEntry = styled.div`
    padding-top: 39px;
    padding-bottom: 100px;
    @media(min-width: 992px){
        padding-left: ${props => props.reverse ? "0" : "95px"};
        padding-top: 73px;
        padding-bottom: 0;
    }
`;

const NewsDate = styled.div`
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    letter-spacing: 2.8px;
    ${fonts.gilroyBold};
    text-transform: uppercase;
    padding-left: 45px;
    @media(min-width: 992px){
        padding-left: 0;
    }
`;

const NewsTitle = styled.div`
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    ${fonts.swissBlackExtended};
    padding-left: 45px;
    padding-top: 25px;
    @media(min-width: 992px){
        padding-left: 0;
        padding-top: 20px;
        max-width: 363px;
    }
`;

const NewsQuote = styled.div`
    font-size: 15px;
    line-height: 21px;
    color: #9CA8B5;
    letter-spacing: 0;
    line-height: 21px;
    ${fonts.gilroyRegular};
    padding-top: 40px;
    padding-bottom: 50px;
    @media(min-width: 992px){
        padding-top: 50px;
        padding-bottom: 30px;
        max-width: 361px;
    }
`;

const NewsImg = styled(Img)`
    height: auto;
    max-width: 348px;
    @media(min-width: 992px){
        max-width: 648px;
        margin-bottom: 140px;
    }
`;

// const ReadMoreButtonContainer = styled.div`
//     cursor: pointer;
//     position: relative;
//     margin-bottom: 80px;
// `;

// const ReadMoreButton = styled.button`
//     cursor: pointer;
//     border: 1px solid #9DDCE2;
//     background: transparent;
//     color: #FFFFFF;
//     text-transform: uppercase;
//     font-size: 12px;
//     ${fonts.gilroyBold};
//     padding-top: 17px;
//     padding-bottom: 15px;
//     letter-spacing: 2.8px;
//     line-height: 14px;
//     width: 200px;
//     transition: background .5s, border .5s, color .5s;
//     -webkit-transition: background .5s, border .5s, color .5s;
//     position: relative;
//     z-index: 1;
//     &:hover,
//     &:active,
//     &:focus {
//         text-decoration: none;
//         outline: none;
//         box-shadow: none;
//         background: #9DDCE2;
//         border: 1px solid white;
//         color: white;
//     }
// `;

// const BorderStyled = styled.div`
//     position: absolute;
//     top: 9px;
//     left: 8px;
//     background-color: transparent;
//     border-bottom: 5px solid #9DDCE2;
//     border-right: 5px solid #9DDCE2;
//     height: 39px;
//     width: 192px;
//     z-index: 0;
// `;

const VerticalLine=styled.img`
    position: absolute;
    margin-left: 22.5px;
    top: -12%;
    @media(min-width: 992px){
        display: none;
    }
`;

const HorizontalLine=styled.img`
    position: absolute;
    top: 31%;
    left: ${props => props.reverse ? "40%" : "-40%"};
    @media(max-width: 992px){
        display: none;
    }
`;

const EventEntry = (props) => {
  const { date, title, intro, thumbnail, reverse, slug } = props
  const Image = (
    <NewsImg fluid={thumbnail.fluid}/>
  );


  if (reverse === true) {
    return (
      <NewsRow>
        <ColStyled lg={7} md={12}>
          {Image}
        </ColStyled>
        <ColStyled lg={5} md={12}>
          <StyledNewsEntry>
            <NewsDate>{date}</NewsDate>
            <NewsTitle>{title}</NewsTitle>
            <VerticalLine src={VerticalLineImg}/>
            <HorizontalLine src={HorizontalLineImg}/>
            <NewsQuote>{documentToReactComponents(intro.json)}</NewsQuote>
            <Button text={"Read more"} link={`/happenings/${slug}`}/>
          </StyledNewsEntry>
        </ColStyled>
      </NewsRow>
    )
  }
  return (
    <NewsRow>
      <ColStyled md={12} lg={7} smOrder={1} lgOrder={2}>
        {Image}
      </ColStyled>
      <ColStyled md={12} lg={5} smOrder={2} lgOrder={1}>
        <StyledNewsEntry reverse>
          <NewsDate>{date}</NewsDate>
          <NewsTitle>{title}</NewsTitle>
          <VerticalLine src={VerticalLineImg}/>
          <HorizontalLine src={HorizontalLineImg} reverse/>
          <NewsQuote>{documentToReactComponents(intro.json)}</NewsQuote>
          <Button text={"Read more"} link={`/happenings/${slug}`}/>
        </StyledNewsEntry>
      </ColStyled>
    </NewsRow>
  )
}

export default EventEntry;
