import React from "react"
import styled from "styled-components"
import { Row, Col, Container } from "styled-bootstrap-grid"
import { Fade } from "react-reveal"
import Img from "gatsby-image"
import fonts from "../../styles/fonts"
import BlockBackgroundImgMobile from "../../resources/images/introducing/intro-background-mobile.jpg"
import BlockBackgroundImg from "../../resources/images/introducing/intro-background.jpg"
import BlueLineImg from "../../resources/images/blue-line.svg"
import DecorationTitle from "./DecorationTitle"
import VideoCarouselBlock from "./VideoCarousel"
import CarouselBackgroundImgMobile from "../../resources/images/introducing/carousel-background-mobile.jpg"
import CarouselBackgroundImg from "../../resources/images/introducing/carousel-background.jpg"
import colors from "../../styles/colors"

const IntroBlackBackground = styled.div`
    width: 100%;
    background: ${colors.darkNavyBackground};
    padding-left: 0;
    padding-right: 0;
`

const IntroBackground = styled.div`
    position: relative;
    background: linear-gradient(0deg, rgba(4,11,20,0) 0%, rgba(4,11,20,0.469625350140056) 86%), url(${BlockBackgroundImgMobile}) no-repeat;
    min-height: 740px;
    background-position-y: 20%;
    background-size: contain;
    @media(min-width: 678px) {
        width: 100vw;
        min-height: 621px;
        height: 100%;
        background: linear-gradient(0deg, rgba(4,11,20,0) 0%, rgba(4,11,20,0.469625350140056) 86%), url(${BlockBackgroundImg}) no-repeat;
        background-position-y: 18%;
        background-size: contain;
    }
`

const CarouselBackground = styled.div`
    position: relative;
    background: url(${CarouselBackgroundImgMobile}) no-repeat;
    background-position-y: 100%;
    background-size: cover;
    @media(min-width: 678px) {
        width: 100vw;
        background: url(${CarouselBackgroundImg}) no-repeat;
        background-position-y: 40%;
        background-size: cover;
    }
`

const ContentContainer = styled(Container)`
    padding: 40px 0 0 0;
`

const StyledRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 33px;
    padding-right: 33px;    
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
`

const PlainRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;    
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`

const BlueLine = styled.img`
    width: 80px;
`

const IntroText = styled.div`
    color: #9CA8B5;
    font-size: 15px;
    line-height: 21px;
    ${fonts.gilroyRegular};
    padding-top: 47px;
    margin-bottom: 75px;
    @media(min-width: 768px){
        padding-right: 0;    
        padding-left: 95px;
        max-width: 456px;
    }
`

const IntroFirstImage = styled(Img)`
    width: 290px;
    height: auto;
    @media(min-width: 768px){
        left: -7%;
    }
    @media(min-width: 992px){
        top: -22%;
        width: 426px;
        position: absolute !important;
    }
    @media(min-width: 1440px){
        left: -27%;
    }
`

const IntroSecondImage = styled(Img)`
    width: 259px;
    height: auto;
    margin-right: 33px;
    float: right;
    margin-top: 64px;
    margin-bottom: 89px;
    @media(min-width: 992px){
        width: 363px;
        margin-top: 60px;
        margin-bottom: 145px;
        float: left;
    }
`

const BoldText = styled.div`
    text-transform: uppercase;
    color: #FFFFFF;
    line-height: 24px;
    font-size: 20px;
    letter-spacing: 1.3px;
    ${fonts.swissBlackExtended};
    margin-bottom: 55px;
    @media(min-width: 992px){
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 1.8px;
        max-width: 600px;
    }
`

const EmailUs = styled.div`
    ${fonts.gilroyRegular};
    font-size: 15px;
    line-height: 21px;
    color: #9CA8B5;
    @media(min-width: 992px){
        max-width: 550px;
    }
`

const Email = styled.a`
    color: white;
    ${fonts.gilroyBold};
    text-decoration: none;
`

const CarouselContainer = styled(Container)`
    padding: 0;
`

const IntroThirdImage = styled(Img)`
    width: 335px;
    height: auto;
    margin-top: 50px;
    margin-left: -80px;
    @media(min-width: 586px){
      margin-left: 0;
    }

    @media(min-width: 992px){
        width: 553px;
        margin-top: 100px;
        margin-bottom: 70px;
    }

`

const IntroBlock = (props) => {
  return (
    <IntroBlackBackground>
      <IntroBackground>
        <ContentContainer>
          <StyledRow>
            <ColStyled md={6}>
              <BlueLine src={BlueLineImg}/>
            </ColStyled>
            <ColStyled lg={6} lgOffset={6} md={12}>
              <Fade duration={750} delay={250}>
                <IntroText>
                  Our love of the sea is matched only by our love of new music. That’s why we’ve launched “LAS
                  Introducing”,
                  a online showcase for the best in undiscovered, unsigned new talent. We regularly showcase these
                  undiscovered talents at our breweries. So keep posted and come for a tour, enjoy a drink or three,
                  meet
                  some the crew and loose yourself in music.
                </IntroText>
              </Fade>
            </ColStyled>
          </StyledRow>
          <PlainRow>
            <ColStyled lg={6}>
              <Fade duration={750} delay={750}>
                <IntroFirstImage fluid={props.introImage1.childImageSharp.fluid} alt={"Intro Image"}/>
              </Fade>
            </ColStyled>
            <ColStyled lg={6}>
              <Fade duration={750} delay={750}>
                <IntroSecondImage fluid={props.introImage2.childImageSharp.fluid} alt={"Intro Image"}/>
              </Fade>
            </ColStyled>
          </PlainRow>
          <StyledRow>
            <ColStyled lg={3} xsOrder={"last"} smOrder="last" lgOrder={"first"}>
              <Fade duration={750} delay={750}>
                <IntroThirdImage fluid={props.introImage3.childImageSharp.fluid} alt={"Intro Image"}/>
              </Fade>
            </ColStyled>
            <ColStyled lg={7} lgOffset={2}>
              <Fade duration={750} delay={1000}>
                <BoldText>
                  LAS Introducing exists to champion the next generation of artists who share our passion for detail,
                  respect for humanity and concern for the natural world.
                </BoldText>
                <EmailUs>
                  If you make music and you’d like to know more, including how you could play at our HQ, please email us
                  on <Email href="mailto: music@lostatsea.co.uk">music@lostatsea.co.uk</Email>
                </EmailUs>
              </Fade>
            </ColStyled>
          </StyledRow>
        </ContentContainer>
        <CarouselBackground>
          <DecorationTitle/>
          <CarouselContainer>
            <StyledRow>
              <VideoCarouselBlock slides={props.slides}/>
            </StyledRow>
          </CarouselContainer>
        </CarouselBackground>
      </IntroBackground>
    </IntroBlackBackground>
  )
}


export default IntroBlock

