import React, { Component } from "react"
import Layout from "../components/core/Layout"
import SEO from "../components/core/Seo"
import get from "lodash/get"
import Hero from "../components/introducing/Hero"
import IntroBlock from "../components/introducing/Intro"
import UpcomingEventsBlock from "../components/introducing/UpcomingEvents"
import PrivateRoute from "../router/PrivateRoute"

class IntroducingPage extends Component {
  render() {
    const path = this.props.location.pathname
    const data = get(this, 'props.data');
    const {
      page,
      introImage1,
      introImage2,
      introImage3,
      upcomingEventsImg1,
      upcomingEventsImg2,
      upcomingEventsImg3,
      carouselImg1,
      articles
    } = data;

    return (
      <Layout>
        <SEO title={page.metaTitle}
             description={page.metaDescription}
             fullTitle={false}
             path={path}/>
        <Hero/>
        <IntroBlock introImage1={introImage1} introImage2={introImage2} introImage3={introImage3}
                    slides={[
                      {
                        image: carouselImg1,
                      },
                    ]}/>
        <UpcomingEventsBlock upcomingEventsImg1={upcomingEventsImg1} upcomingEventsImg2={upcomingEventsImg2} upcomingEventsImg3={upcomingEventsImg3} articles={articles.nodes.slice(0, 3)}/>
      </Layout>
    )
  }
}

function introducing(props) {
  return <PrivateRoute component={IntroducingPage} {...props}/>
}

export default introducing;

export const pageQuery = graphql`
  query IntroducingPageQuery {
    page: contentfulLandingPage(slug: { eq: "introducing" }) {
      id
      metaTitle
      metaDescription
    }
    articles: allContentfulArticle(filter: {category: {category: {eq: "Events"}}}) {
      nodes {
        title
        date(formatString: "MMM DD, YYYY")
        slug
        intro {
          json
        }
        thumbnail {
          fluid(maxWidth: 1100, quality: 90) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
    introImage1: file(relativePath: { eq: "introducing/introducing-opt-01.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 426) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    introImage2: file(relativePath: { eq: "introducing/introducing-opt-02.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 363) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    introImage3: file(relativePath: { eq: "introducing/introducing-opt-03.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 553) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    upcomingEventsImg1: file(relativePath: { eq: "introducing/upcoming-events-image-1.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
      upcomingEventsImg2: file(relativePath: { eq: "introducing/upcoming-events-image-2.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    upcomingEventsImg3: file(relativePath: { eq: "introducing/upcoming-events-image-3.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
      carouselImg1: file(relativePath: { eq: "introducing/placeholder.jpg" }) {
          childImageSharp {
              fluid(quality: 80, maxWidth: 1200) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
              }
          }
      }
  }
`;
